import Link from "next/link";

import { CallToActionComponentFragment } from "../../interfaces";

type Props = Partial<CallToActionComponentFragment>;

const CallToAction = (props: Props) => {
  return (
    <div className="bg-gray-50">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">{props.highlightedTitlePrefix}</span>{" "}
          <span className="block text-primary">{props.title}</span>
        </h2>
        <div className="flex mt-8 lg:mt-0 lg:flex-shrink-0">
          {props.primaryButton && (
            <div className="inline-flex rounded-md shadow">
              <Link href={props.primaryButtonLink || "#"} passHref>
                <a className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:bg-lime-500">
                  {props.primaryButton}
                </a>
              </Link>
            </div>
          )}
          {props.secondaryButton && (
            <div className="inline-flex ml-3 rounded-md shadow">
              <Link href={props.secondaryButtonLink || "#"} passHref>
                <a className="inline-flex items-center justify-center px-5 py-3 text-base font-medium bg-white border border-transparent rounded-md text-primary hover:bg-indigo-50">
                  {props.secondaryButton}
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
