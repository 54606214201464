import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { FAQPageJsonLd } from "next-seo";

import { CenteredAccordionComponentFragment } from "../../interfaces";
import classNames from "../../lib/classNames";
import RichTextRenderer from "../RichTextRenderer";

type Props = Partial<CenteredAccordionComponentFragment>;

const CenteredAccordion = (props: Props) => {
  return (
    <>
      <FAQPageJsonLd
        mainEntity={
          props.questions?.map((q) => ({
            questionName: q.question,
            acceptedAnswerText: q.answer.text,
          })) || []
        }
      />
      <div className="bg-gray-50">
        <div className="px-4 py-12 mx-auto max-w-7xl sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <h2 className="text-3xl font-extrabold text-center text-gray-900 sm:text-4xl">
              {props.title}
            </h2>
            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {props.questions?.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="flex items-center ml-6 h-7">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel
                        as="dd"
                        className="pr-12 mt-2"
                        unmount={false}
                      >
                        <span className="text-base text-gray-500">
                          <RichTextRenderer content={faq.answer.raw} />
                        </span>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};

export default CenteredAccordion;
