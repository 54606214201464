import { NextSeo } from "next-seo";

import { PageFragment } from "../interfaces";
import baseUrl from "../lib/baseUrl";
import ComponentSwitch from "./ComponentSwitch";
import RichTextRenderer from "./RichTextRenderer";

type Props = {
  page: PageFragment;
};

const Page = ({ page }: Props) => {
  return (
    <>
      <NextSeo
        title={page.seo.title || page.title || ""}
        description={page.seo.description || page.description || ""}
        noindex={page.seo.noIndex}
        openGraph={{
          title: page.seo.title || page.title || "",
          description: page.seo.description || page.description || "",
        }}
        canonical={
          page.seo.slug === "home" ? baseUrl : `${baseUrl}/${page.seo.slug}`
        }
      />
      {page.highlightedTitlePrefix && (
        <div className="relative py-12 overflow-hidden bg-white">
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="mx-auto text-lg max-w-prose">
              {(page.highlightedTitlePrefix || page.title) && (
                <h1>
                  <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                    {page.highlightedTitlePrefix}
                  </span>
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
                    {page.title}
                  </span>
                </h1>
              )}
              {page.description && (
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  {page.description}
                </p>
              )}
            </div>
            {page.content?.raw && (
              <div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-green">
                <RichTextRenderer content={page.content?.raw} />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="relative overflow-hidden">
        {page.components && <ComponentSwitch components={page.components} />}
      </div>
      {page.content?.raw && (
        <div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-green">
          <RichTextRenderer content={page.content?.raw} />
        </div>
      )}
    </>
  );
};

export default Page;
