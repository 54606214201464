import Link from "next/link";

import { SplitHeroComponentFragment } from "../../interfaces";
import LogoWithBackground from "../../public/logo_with_background.png";
import BlurImage from "../BlurImage";

type Props = Partial<SplitHeroComponentFragment>;

const SplitHero = (props: Props) => {
  return (
    <div className="lg:relative">
      <div className="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">{props.title}</span>{" "}
            <span className="block text-primary xl:inline">
              {props.highlightedTitleSuffix}
            </span>
          </h1>
          <p className="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            {props.description}
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            {props.primaryButton && (
              <div className="rounded-md shadow">
                <Link href={props.primaryButtonLink || "#"} passHref>
                  <a className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:bg-lime-500 md:py-4 md:text-lg md:px-10">
                    {props.primaryButton}
                  </a>
                </Link>
              </div>
            )}
            {props.secondaryButton && (
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                <Link href={props.secondaryButtonLink || "#"} passHref>
                  <a className="flex items-center justify-center w-full px-8 py-3 text-base font-medium bg-white border border-transparent rounded-md text-primary hover:bg-gray-50 md:py-4 md:text-lg md:px-10">
                    {props.secondaryButton}
                  </a>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <BlurImage
          className="absolute object-cover object-left-top w-full h-full"
          src={props.image?.url || LogoWithBackground}
          width="2022"
          height="1349"
          alt={props.imageAlt || props.title || ""}
          priority={true}
          sizes="(max-width: 768px) 100vw,
                  50vw"
        />
      </div>
    </div>
  );
};

export default SplitHero;
