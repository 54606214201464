import { PageByIdQuery, Treatment } from "../interfaces";
import AlternatingWithTestimonial from "./sections/AlternatingWithTestimonial";
import Blog3ColumnCard from "./sections/Blog3ColumnCard";
import CallToAction from "./sections/CallToAction";
import Centered2ColumnFeature from "./sections/Centered2ColumnFeature";
import Centered3ColumnFeature from "./sections/Centered3ColumnFeature";
import CenteredAccordion from "./sections/CenteredAccordion";
import OffsetWithSupportingText from "./sections/OffsetWithSupportingText";
import OffWhiteGrid from "./sections/OffWhiteGrid";
import SimpleCenteredHeader from "./sections/SimpleCenteredHeader";
import SplitBrandContactForm from "./sections/SplitBrandContactForm";
import SplitHero from "./sections/SplitHero";
import Team from "./sections/Team";
import TestimonialsWithOverlappingImage from "./sections/TestimonialsWithOverlappingImage";
import WithStarRating from "./sections/WithStarRating";

type Props = {
  components:
    | NonNullable<PageByIdQuery["page"]>["components"]
    | Treatment["components"];
};

const ComponentSwitch = (props: Props) => {
  const elemetns = props.components.map((component) => {
    switch (component.__typename) {
      case "SimpleCenteredHeader":
        return <SimpleCenteredHeader key={component.id} {...component} />;
      case "Centered2ColumnFeature":
        return <Centered2ColumnFeature key={component.id} {...component} />;
      case "CenteredAccordion":
        return <CenteredAccordion key={component.id} {...component} />;
      case "CallToAction":
        return <CallToAction key={component.id} {...component} />;
      case "AlternatingWithTestimonial":
        return <AlternatingWithTestimonial key={component.id} {...component} />;
      case "SplitBrandContactForm":
        return <SplitBrandContactForm key={component.id} {...component} />;
      case "TestimonialsWithOverlappingImage":
        return (
          <TestimonialsWithOverlappingImage key={component.id} {...component} />
        );
      case "Centered3ColumnFeature":
        return <Centered3ColumnFeature key={component.id} {...component} />;
      case "SplitHero":
        return <SplitHero key={component.id} {...component} />;
      case "OffsetWithSupportingText":
        return <OffsetWithSupportingText key={component.id} {...component} />;
      case "Blog3ColumnCard":
        return <Blog3ColumnCard key={component.id} {...component} />;
      case "Team":
        return <Team key={component.id} {...component} />;
      case "OffWhiteGrid":
        return <OffWhiteGrid key={component.id} {...component} />;
      case "WithStarRating":
        return <WithStarRating key={component.id} {...component} />;
      default:
        return undefined;
    }
  });

  return <>{elemetns}</>;
};

export default ComponentSwitch;
