import * as Icons from "@heroicons/react/outline";
import Link from "next/link";

import { Centered2ColumnFeatureComponentFragment } from "../../interfaces";

type Props = Partial<Centered2ColumnFeatureComponentFragment>;

const Centered2ColumnFeature = (props: Props) => {
  const features = props.features?.map((feature) => {
    return {
      ...feature,
      icon: Icons[feature.icon as keyof typeof Icons],
    };
  });

  return (
    <div className="py-12 bg-white">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base font-semibold tracking-wide uppercase text-primary">
            {props.superTitle}
          </h2>
          <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            {props.title}
          </p>
          <p className="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">
            {props.description}
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features?.map((feature) => (
              <Link href={feature.link || "#"} key={feature.id} passHref>
                <a className="relative">
                  <dt>
                    {feature.icon && (
                      <div className="absolute flex items-center justify-center w-12 h-12 text-white rounded-md bg-primary">
                        <feature.icon className="w-6 h-6" aria-hidden="true" />
                      </div>
                    )}
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {feature.description}
                  </dd>
                </a>
              </Link>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Centered2ColumnFeature;
