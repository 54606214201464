import { TestimonialsWithOverlappingImageComponentFragment } from "../../interfaces";
import classNames from "../../lib/classNames";
import BlurImage from "./../BlurImage";

type Props = Partial<TestimonialsWithOverlappingImageComponentFragment>;

const TestimonialsWithOverlappingImage = (props: Props) => {
  return (
    <div>
      {props.testimonials &&
        props.testimonials?.map((testimonial, testimonialIdx) => (
          <div className="pt-16 bg-white lg:py-24" key={testimonial.id}>
            <div className="pb-16 bg-primary lg:pb-0 lg:z-10 lg:relative">
              <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-8">
                <div
                  className={classNames(
                    testimonialIdx % 2 === 0 ? "" : "order-last",
                    "relative lg:-my-8"
                  )}
                >
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-0 bg-white h-1/2 lg:hidden"
                  />
                  <div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                    <div className="overflow-hidden shadow-xl bg-secondary aspect-w-10 aspect-h-10 rounded-xl lg:h-full">
                      <BlurImage
                        className="object-contain lg:h-full lg:w-full rounded-xl"
                        src={
                          testimonial?.image?.url || "/avatar-placeholder.svg"
                        }
                        alt={testimonial.name + "'s testimonial"}
                        sizes="(max-width: 768px) 90vw,
                                (max-width: 1200px) 90vw,
                                33vw"
                        fill
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    testimonialIdx % 2 === 0 ? "lg:pl-8" : "lg:pr-8",
                    "mt-12 lg:m-0"
                  )}
                >
                  <div className="max-w-md px-4 mx-auto sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                    <blockquote>
                      <div>
                        <svg
                          className="w-12 h-12 text-white opacity-25"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="mt-6 text-2xl font-medium text-white">
                          {testimonial.content}
                        </p>
                      </div>
                      <footer className="mt-6">
                        <p className="text-base font-medium text-white">
                          {testimonial.name}
                        </p>
                        <p className="text-base font-medium text-indigo-100">
                          {testimonial.position}
                        </p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default TestimonialsWithOverlappingImage;
