import { StarIcon } from "@heroicons/react/solid";

import { WithStarRatingComponentFragment } from "../../interfaces";
import BlurImage from "../BlurImage";

type Props = Partial<WithStarRatingComponentFragment>;

const WithStarRating = ({ testimonial }: Props) => {
  return (
    <section className="bg-white px-6 py-24 sm:py-20 lg:px-8">
      <figure className="mx-auto max-w-2xl">
        <p className="sr-only">{testimonial?.rating} out of 5 stars</p>
        <div className="flex gap-x-1 text-indigo-600">
          {[...Array(5)].map((_, i) => (
            <StarIcon
              key={i}
              className={`h-5 w-5 flex-none ${
                testimonial?.rating && i + 1 <= testimonial.rating
                  ? "text-indigo-500"
                  : "text-gray-400"
              }`}
              aria-hidden="true"
            />
          ))}
        </div>
        <blockquote className="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
          <p>“{testimonial?.content}”</p>
        </blockquote>
        <figcaption className="mt-10 flex items-center gap-x-6">
          {testimonial?.image && (
            <BlurImage
              className="h-12 w-12 rounded-full bg-gray-50"
              src={testimonial?.image?.url}
              alt=""
              width={48}
              height={48}
            />
          )}
          <div className="text-sm leading-6">
            <div className="font-semibold text-gray-900">
              {testimonial?.name}
            </div>
            {testimonial?.position && (
              <div className="mt-0.5 text-gray-600">{testimonial.position}</div>
            )}
          </div>
        </figcaption>
      </figure>
    </section>
  );
};

export default WithStarRating;
