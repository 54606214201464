import Link from "next/link";

import { OffsetWithSupportingTextComponentFragment } from "../../interfaces";
import RichTextRenderer from "../RichTextRenderer";

type Props = Partial<OffsetWithSupportingTextComponentFragment>;

const OffsetWithSupportingText = (props: Props) => {
  return (
    <div className="bg-white">
      <div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:py-20 lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div>
            <h2 className="text-3xl font-extrabold text-gray-900">
              {props.title}
            </h2>
            <p className="mt-4 text-lg text-gray-500">{props.description}</p>
            {props.button && (
              <Link href={props.buttonLink || "#"} passHref>
                <a>
                  <button className="inline-flex items-center px-4 py-2 mt-6 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary hover:bg-lime-500">
                    {props.button}
                  </button>
                </a>
              </Link>
            )}
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2">
            <dl className="space-y-12">
              {props.questions?.map((item) => (
                <div key={item.question}>
                  <dt className="text-lg font-medium leading-6 text-gray-900">
                    {item.question}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <RichTextRenderer content={item.answer.raw} />
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffsetWithSupportingText;
