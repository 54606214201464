import { TeamComponentFragment } from "../../interfaces";
import LogoWithBackground from "../../public/logo_with_background.png";
import BlurImage from "../BlurImage";
import RichTextRenderer from "../RichTextRenderer";

type Props = Partial<TeamComponentFragment>;

const Team = (props: Props) => {
  return (
    <div className="bg-white">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="lg:col-span-3">
            <ul
              role="list"
              className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
            >
              {props.members?.map((person) => (
                <li key={person.name} className="sm:py-8">
                  <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                    <div className="aspect-w-3 aspect-h-4 sm:aspect-w-3 sm:aspect-h-4">
                      <BlurImage
                        className="object-cover rounded-lg shadow-lg"
                        src={person.image?.url || LogoWithBackground}
                        alt={person.name}
                        fill
                        sizes="(max-width: 768px) 95vw,
                                33vw"
                      />
                    </div>
                    <div className="sm:col-span-2">
                      <div className="space-y-4">
                        <div className="space-y-1 text-lg font-medium leading-6">
                          <h3>{person.name}</h3>
                          <p className="text-indigo-600">
                            {person.speciality.join(" / ")}
                          </p>
                        </div>
                        <div className="text-lg text-gray-500">
                          {person.resume && (
                            <RichTextRenderer content={person.resume.raw} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
