import * as Icons from "@heroicons/react/outline";
import Link from "next/link";

import { AlternatingWithTestimonialComponentFragment } from "../../interfaces";
import classNames from "../../lib/classNames";
import BlurImage from "../BlurImage";
import RichTextRenderer from "../RichTextRenderer";

type Props = Partial<AlternatingWithTestimonialComponentFragment>;

const AlternatingWithTestimonial = (props: Props) => {
  const remaning = props.reverse ? 1 : 0;
  const sections = props.sections?.map((section) => {
    return section.icon
      ? { ...section, icon: Icons[section.icon as keyof typeof Icons] }
      : section;
  });

  return (
    <div className="relative pt-16 pb-32 overflow-hidden bg-white">
      {sections?.map((section, sectionIdx) => (
        <div
          className={classNames(sectionIdx === 0 ? "relative" : "mt-24")}
          key={section.id}
        >
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div
              className={classNames(
                sectionIdx % 2 === remaning
                  ? "lg:py-16"
                  : "lg:py-32 lg:col-start-2",
                "max-w-xl px-4 mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0"
              )}
            >
              <div>
                {section.icon && (
                  <div>
                    <span className="flex items-center justify-center w-12 h-12 rounded-md bg-primary">
                      <section.icon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                )}
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    {section.name}
                  </h2>
                  <div className="mt-4 text-xl text-gray-500">
                    {section.description && (
                      <RichTextRenderer content={section.description.raw} />
                    )}
                  </div>
                  {section.button && (
                    <div className="mt-6">
                      <Link href={section.buttonLink || "#"} passHref>
                        <a className="inline-flex px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-secondary hover:bg-lime-500">
                          {section.button}
                        </a>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              {section.testimonial && (
                <div className="pt-6 mt-8 border-t border-gray-200">
                  <blockquote>
                    <div>
                      <p className="text-base text-gray-500">
                        {section.testimonial.content}
                      </p>
                    </div>
                    <footer className="mt-3">
                      <div className="flex items-center space-x-3">
                        <div className="relative flex-shrink-0 w-6 h-6">
                          <BlurImage
                            className="absolute rounded-full"
                            src={
                              section.testimonial.image?.url ||
                              "/avatar-placeholder.svg"
                            }
                            width="32"
                            height="32"
                            alt={section.testimonial.name + " avatar"}
                          />
                        </div>
                        <div className="text-base font-medium text-gray-700">
                          {section.testimonial.name}
                          {section.testimonial.position &&
                            `, ${section.testimonial.position}`}
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              )}
            </div>
            <div
              className={classNames(
                sectionIdx % 2 === remaning ? "" : "lg:col-start-1",
                "mt-12 sm:mt-16 lg:mt-0"
              )}
            >
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <BlurImage
                  className={classNames(
                    sectionIdx % 2 === remaning ? "lg:left-0" : "lg:right-0",
                    "object-cover w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:h-full lg:w-auto lg:max-w-none"
                  )}
                  src={section.image.url}
                  alt={section.imageAlt || ""}
                  width="1011"
                  height="675"
                  sizes="(max-width: 768px) 90vw,
                          50vw"
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AlternatingWithTestimonial;
