import Link from "next/link";

import { OffWhiteGridComponentFragment } from "../../interfaces";
import BlurImage from "../BlurImage";

type Props = Partial<OffWhiteGridComponentFragment>;

const OffWhiteGrid = ({
  superTitle,
  title,
  testimonials,
  buttonText,
  buttonLink,
}: Props) => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          {superTitle && (
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
              Testimonials
            </h2>
          )}
          {title && (
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              We have worked with thousands of amazing people
            </p>
          )}
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials &&
              testimonials.map((testimonial) => (
                <div
                  key={testimonial.id}
                  className="pt-8 sm:w-full sm:px-4"
                >
                  <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
                    <blockquote className="text-gray-900">
                      <p>{`“${testimonial.content}”`}</p>
                    </blockquote>
                    <figcaption className="mt-6 flex items-center gap-x-4">
                      {testimonial.image && (
                        <BlurImage
                          className="h-10 w-10 rounded-full bg-gray-50"
                          src={testimonial.image?.url}
                          alt=""
                          width={48}
                          height={48}
                        />
                      )}
                      <div>
                        <div className="font-semibold text-gray-900">
                          {testimonial.name}
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              ))}
          </div>
        </div>
        {buttonText && (
          <div className="mx-auto max-w-xl text-center pt-12">
            <Link href={buttonLink || "#"} passHref>
              <button className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-white border border-transparent rounded-md bg-secondary hover:bg-lime-500">
                {buttonText}
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default OffWhiteGrid;
