import * as Icons from "@heroicons/react/outline";
import Link from "next/link";

import { Centered3ColumnFeatureComponentFragment } from "../../interfaces";

type Props = Partial<Centered3ColumnFeatureComponentFragment>;

const Centered3ColumnFeature = (props: Props) => {
  const features = props.features?.map((feature) => {
    return {
      ...feature,
      icon: Icons[feature.icon as keyof typeof Icons],
    };
  });

  return (
    <div className="relative py-16 bg-white sm:py-24 lg:py-32">
      <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="text-base font-semibold tracking-wider uppercase text-primary">
          {props.superTitle}
        </h2>
        <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          {props.title}
        </p>
        <p className="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
          {props.description}
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features?.map((feature) => (
              <Link href={feature.link || "#"} key={feature.name} passHref>
                <a className="pt-6">
                  <div className="flow-root px-6 pb-8 rounded-lg bg-gray-50">
                    <div className="-mt-6">
                      {feature.icon ? (
                        <div>
                          <span className="inline-flex items-center justify-center p-3 rounded-md shadow-lg bg-primary">
                            <feature.icon
                              className="w-6 h-6 text-white"
                              aria-hidden="true"
                            />
                          </span>
                        </div>
                      ) : <div className="pt-3" />}
                      <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">
                        {feature.name}
                      </h3>
                      <p className="mt-5 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </a>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Centered3ColumnFeature;
